var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entity flex items-center justify-between px-4 justify-between gap-4",class:{
        'bg-white': !_vm.item.selected && !_vm.item.warning && !_vm.item.error,
        'bg-primary-50': _vm.item.selected,
        'bg-error': _vm.item.error && !_vm.item.selected,
        'hover:bg-primary-100 cursor-pointer': _vm.selectable,
        'opacity-20 pointer-events-none cursor-not-allowed ': _vm.item.disabled
    },on:{"click":_vm.onSelect}},[_c('div',{staticClass:"entity__content flex items-center justify-center gap-2"},[_c('div',{staticClass:"entity__icon-wrapper flex items-center justify-center relative rounded-full"},[_c('div',{staticClass:"entity__icon-underlay bg-error"}),_c(_vm.entityTypesConfig[_vm.item.type].icon,{tag:"component",staticClass:"entity__icon"})],1),_c('span',{staticClass:"entity__name text-sm",attrs:{"title":_vm.item.name || ''}},[_vm._v(" "+_vm._s(_vm.item.name || '--')+" ")])]),(!_vm.readonly)?_c('div',{staticClass:"entity__controls ml-auto",attrs:{"title":"Delete"}},[_c('button',{staticClass:"flex items-center justify-center text-primary hover:text-primary-300",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove')}}},[_c('TrashIcon',{staticClass:"entity__icon"})],1)]):(_vm.item.checked)?_c('div',{staticClass:"entity__check ml-auto flex items-center justify-center relative rounded-full bg-success"},[_c('font-awesome-icon',{staticClass:"entity__icon text-primary-content text-xs",attrs:{"icon":"check"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }