<template>
    <div class="collect-info flex flex-col w-full gap-5">
        <UIContentBox class="checks-list ">
            <template v-if="loading">
                <div class="flex w-full">
                    <Loader :size="8" class="mx-auto my-5" />
                </div>
            </template>
        
        
            <Empty
                v-else-if="!mappedItems.length"
                text="This entity does not have any active check assigned"
                classList="text-lg"
                :showBtn="false"
            />
            <template v-else>
                <p class="font-bold pb-3">Checks included in the Packages</p>
        
                <ul class="grid grid-cols-4 gap-x-4  gap-y-4">
                    <li
                        v-for="(item, ind) in mappedItems"
                        :key="item.check_id"
                        class="border-2 rounded-xl py-2 px-4 border-box-stroke checks-list__item"
                    >
                        <p class="flex">
                            <span class="pr-4 font-semibold text-md self-center">{{ ind + 1 }} .</span>
                            <span class="flex flex-col">
                                <span class="truncate" :title="item.check_name">{{ item.check_name }}</span>
                                <span 
                                    v-if="item.entityTypes.length > 0" 
                                    class="truncate text-sm text-blue-600" 
                                    :title="check.entityTypes"
                                >
                                    {{ item.entityTypes }}
                                </span>
                            </span>
                        </p>
                    </li>
                </ul>
            </template>
        </UIContentBox>
        <UIContentBox class="flex items-center justify-center">
            <Button
                v-if="!allSaved"
                :loader="loading"
                type="primary"
                @click="$emit(isLast ? 'next' : 'skip')"
                text="Next"
            />
            <Button
                v-else
                :loader="loading"
                class="bg-success text-white"
                @click="$emit('submit')"
                text="Submit"
            />
        </UIContentBox>
    </div>
</template>

<script>
import Loader from "@shared/loader";
import Empty from "@shared/empty";
import Button from "@shared/components/button";

export default {
    name: "ChecksList",

    components: {
        Loader,
        Empty,
        Button,
    },

    props: {
        items: {
            type: Array,
            required: true,
            default: () => [],
        },
        loading: {
            type: Boolean,
            required: false,
        },
        isLast: {
            type: Boolean,
            required: false,
        },
        allSaved: {
            type: Boolean,
            required: false,
        },
    },

    computed: {
        mappedItems() {
            return (this.items || []).map(item => ({
                ...item,
                entityTypes: item?.entity_types?.map(type => type.name).join(', '),
            }));
        },
    },
}
</script>

<style lang="scss" scoped>
.checks-list {
    &__item {
        flex: 1 1 calc(33% - 1rem);
    }
}
</style>
