export const CASE_CREATION_STEPS = {
    ENTITY_DETAILS: 'entity_details',
    COLLECT_INFO: 'collect_info',
    CHECKS: 'checks',
    SUBMIT: 'submit',
}

export const CHANGE_STATUSES = {
    ADDED: 'added',
    UPDATED: 'updated',
    DELETED: 'deleted',
}