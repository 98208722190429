<template>
    <div class="entity-bar h-10 rounded-lg bg-primary-content w-full overflow-hidden flex items-stretch">
        <div class="entity-bar__items overflow-auto scroll-bar flex items-stretch">
            <Item
                v-for="(item, i) in items"
                :key="item.id"
                :item="item"
                class="entity-bar__item"
                :class="{
                    'border-r border-box-stroke': i + 1 < items.length,
                }"
                :readonly="readonly || item.readonly"
                :selected="item.selected"
                :disabled="item.disabled"
                :selectable="isBeforeSelected(i)"
                @remove="$emit('remove', item)"
                @select="$emit('select', item)"
            />
        </div>
    </div>
</template>

<script>
import Item from "./components/EntityItem";

export default {
    components: {
        Item,
    },
    props: {
        // use positive value:
        step: {
            type: Number,
            default: 1,
        },
        /**
         * @typedef {Object} Item
         * @property {number} id
         * @property {string} name
         */
        /**
         * @type {Item[]}
         */
        items: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        isBeforeSelected(index) {
            const selectedIndex = this.items.findIndex((el) => !!el.selected);
            return index < selectedIndex;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>