<template>
    <div
        class="entity flex items-center justify-between px-4 justify-between gap-4"
        :class="{
            'bg-white': !item.selected && !item.warning && !item.error,
            'bg-primary-50': item.selected,
            'bg-error': item.error && !item.selected,
            'hover:bg-primary-100 cursor-pointer': selectable,
            'opacity-20 pointer-events-none cursor-not-allowed ': item.disabled
        }"
        @click="onSelect"
    >
        <div class="entity__content flex items-center justify-center gap-2">
            <div class="entity__icon-wrapper flex items-center justify-center relative rounded-full">
                <div class="entity__icon-underlay bg-error" />
                <component
                    :is="entityTypesConfig[item.type].icon"
                    class="entity__icon"
                />
            </div>
            <span
                class="entity__name text-sm"
                :title="item.name || ''"
            >
                {{ item.name || '--' }}
            </span>
        </div>
        <div
            v-if="!readonly"
            class="entity__controls ml-auto"
            title="Delete"
        >
            <button
                class="flex items-center justify-center text-primary hover:text-primary-300"
                @click.stop="$emit('remove')"
            >
                <TrashIcon class="entity__icon" />
            </button>
        </div>
        <div
            v-else-if="item.checked"
            class="entity__check ml-auto flex items-center justify-center relative rounded-full bg-success"
        >
            <font-awesome-icon
                class="entity__icon text-primary-content text-xs"
                icon="check"
            />
        </div>
    </div>
</template>

<script>
import { ENTITY_TYPE_NAMES } from "@shared/utils/constants.js";
import TrashIcon from "@shared/assets/icons/trash.svg";
const CaseIcon = () => import("@shared/assets/icons/entity/case.svg");
const PersonIcon = () => import("@shared/assets/icons/entity/person.svg");
const CompanyIcon = () => import("@shared/assets/icons/entity/company.svg");

export default {
    components: {
        TrashIcon,
    },
    props: {
        /**
         * @typedef {Object} Item
         * @property {number} id
         * @property {string} type
         * @property {string} [name]
         * @property {boolean} [active]
         * @property {boolean} [checked]
         */
        /**
         * @type {Item}
         */
        item: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        selectable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {}
    },
    computed: {
        entityTypesConfig() {
            return {
                [ENTITY_TYPE_NAMES.CASE]: {
                    id: ENTITY_TYPE_NAMES.CASE,
                    icon: CaseIcon,
                },
                [ENTITY_TYPE_NAMES.PERSON]: {
                    id: ENTITY_TYPE_NAMES.PERSON,
                    icon: PersonIcon,
                },
                [ENTITY_TYPE_NAMES.COMPANY]: {
                    id: ENTITY_TYPE_NAMES.COMPANY,
                    icon: CompanyIcon,
                },
            }
        },
    },
    methods: {
        onSelect() {
            if (this.selectable) this.$emit('select');
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.entity {
    &__icon-wrapper, &__check {
        overflow: hidden;
        width: toRem(22px);
        height: toRem(22px);
        flex-shrink: 0;
    }
    &__icon-underlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: .2;
    }
    &__name {
        max-width: toRem(80px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>