<template>
    <div
        class="case-creation h-full flex flex-col max-h-full overflow-hidden"
        v-allow:auth="'case.create'"
    >
        <div
            v-if="loading"
            class="p-10 flex items-center justify-center"
        >
            <Loader />
        </div>
        <template v-else>
            <AlertInfo
                v-if="!selectedClientId"
                class="text-sm mx-4 bg-blue-100 text-blue-900"
                message="To create a case first select a client from left menu &amp; then click on create case on right."
            />
            <div class="flex overflow-hidden h-full">
                <ClientList
                    v-if="showClientList"
                    :loading="loaders.clients"
                    :list="clients"
                    :selected="selectedClient"
                    @select="selectClient"
                />
                <CaseCreationFlow
                    v-if="showCaseCreation"
                    :loading="loaders.packages"
                    :tenant-id="getTenantId"
                    :client-id="selectedClient.id"
                    :preselected-package-id="selectedPackageId"
                    :package-list="packages"
                    portal="tenant"
                    @cancel="exitCaseCreation"
                    @select:package="onPackageSelect"
                />
            </div>
        </template>
    </div>
</template>

<script>
import axios from "@/axios";
import CaseCreationFlow from "@shared/case-creation/CaseCreationFlow";
import Loader from "@shared/loader/index.vue";
import AlertInfo from "@/components/alert-box";
import ClientList from "@shared/case-creation/ClientList.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "create-case",
    components: {
        CaseCreationFlow,
        Loader,
        AlertInfo,
        ClientList,
    },
    title: "Create Case",
    data() {
        return {
            loading: false,
            selectedPackageId: null,
            selectedClientId: null,
            clients: [],
            packages: [],
            loaders: {
                clients: false,
                packages: false,
            },
        };
    },
    computed: {
        ...mapGetters([
            'getTenantId',
        ]),
        selectedClient() {
            return this.selectedClientId ? this.clients.find((el) => el.id === this.selectedClientId) : null;
        },
        showCaseCreation() {
            return !!this.selectedClient;
        },
        showClientList() {
            return !(this.showCaseCreation && this.selectedPackageId);
        },
    },
    async mounted() {
        this.loading = true;
        if (!this.getTenantId) {
            await this.fetchTenantId();
        }
        await this.fetchAllClients();        

        const { client, packageid } = this.$route.query;
        this.selectedClientId = client || null;
        this.selectedPackageId = packageid || null;
        this.loading = false;
        if (client) {
            await this.fetchPackages(client);
        }
    },
    methods: {
        ...mapActions([
            'fetchTenantId',
        ]),
        async fetchAllClients() {
            try {
                this.loaders.clients = true;
                this.clients = [];
                const { data } = await axios(`tenant/${this.getTenantId}/client`);
                this.clients = data;
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.loaders.clients = false;
        },
        async selectClient(client) {
            this.resetClient(client.id);
            await this.fetchPackages(this.selectedClientId);
            this.$router.push({ query: { client: this.selectedClientId } });
        },
        async fetchPackages(client_id) {
            try {
                this.packages = [];
                this.loaders.packages = true;
                const { data } = await axios(`screening-package/client/${client_id}`);
                this.packages = data;
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.loaders.packages = false;
        },
        resetClient(payload = null) {
            this.packages = [];
            this.selectedClientId = payload;
        },
        exitCaseCreation() {
            this.$router.push({ query: { client: this.selectedClientId } });
            this.selectedPackageId = null;
        },
        onPackageSelect(payload) {
            this.selectedPackageId = payload;
        },
    },
};

</script>

<style lang="scss" scoped>
</style>
