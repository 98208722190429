<template>
  <div
    class="case-creation-flow h-full flex flex-col max-h-full overflow-hidden flex-grow"
  >
    <div v-if="isLoading" class="p-10 flex items-center justify-center w-full">
      <Loader />
    </div>
    <template v-else>
      <div v-if="!showCaseCreation" class="flex overflow-hidden h-full w-full">
        <PackageList
          :loading="loading"
          :list="packageList"
          :packageLoaders="packageLoaders"
          @select="selectPackage($event.id)"
        />
      </div>
      <div v-else class="flex flex-col w-full gap-5 flex-1">
        <NavBar :timeline="timelineData" @back="onBack" @cancel="onCancel" />
        <EntityBar
          :step="timelineData.currentStepIndex"
          :items="computedEntitiesList"
          :readonly="currentStepIndex > 1"
          @remove="removeEntity"
          @select="onSelectEntity($event.id)"
        />
        <div class="case-creation__body flex flex-col w-full flex-1">
          <div
            v-if="loaders.entity || loaders.step"
            class="p-10 flex items-center justify-center w-full"
          >
            <Loader />
          </div>
          <template v-else>
            <template
              v-if="checkCurrentStep(CASE_CREATION_STEPS.ENTITY_DETAILS)"
            >
              <FormBuilder
                ref="case-creation-form-builder"
                v-if="selectedEntity?.form?.formData && !showEntityChangeNav"
                :data="selectedEntity.form.formData"
                :saveSectionMethod="saveEntitySection"
                @submit="submitEntityForm"
                :gapErrors="{}"
                :printableSections="false"
                :userInfo="{}"
                :formStates="formStates"
                :case_id="tempCaseId"
                :actions-names="formBuilderActionsNames"
                :entity-id="selectedEntity?.id"
              />
              <EntityChangeNav
                v-else-if="showEntityChangeNav"
                class="flex-1"
                :add-options="entitiesToAdd"
                :allow-next="allowNextForm"
                :allow-submit="allowSubmitForm"
                @add="addEntity"
                @next="selectNextEntity"
                @submit="nextStep"
              />
            </template>
            <CollectInfo
              v-else-if="checkCurrentStep(CASE_CREATION_STEPS.COLLECT_INFO)"
              v-model="entityDataCollectionModel"
              :userOptions="collectInfoData.userList"
              :sequenceOptions="collectInfoData.sequenceList"
              :formOptions="collectInfoData.formList"
              :loading="loaders.form"
              :is-last="!isNextEntity"
              :all-saved="allDataCollectionsSaved"
              @save="saveCollectInfo"
              @submit="saveCollectInfo"
            />
            <CheckList
              v-else-if="checkCurrentStep(CASE_CREATION_STEPS.CHECKS)"
              :items="currentEntityCheckList"
              :is-last="!isNextEntity"
              @skip="selectNextEntity"
              @next="nextStep"
            />
            <CheckList
              v-else-if="checkCurrentStep(CASE_CREATION_STEPS.SUBMIT)"
              :items="currentEntityCheckList"
              all-saved
              @submit="submitCase"
            />
          </template>
        </div>
      </div>
    </template>
    <ModalConfirm ref="confirm-popup" />
    <ModalConfirm ref="finish-popup">
      <template #content>
        <ul class="submit-confirmation-text">
          <li>Are you sure you've added all the entity details.</li>
          <li>
            Selected all the forms you know the emails that you need to send.
          </li>
          <li>Selected all the checks.</li>
        </ul>
      </template>
    </ModalConfirm>
  </div>
</template>

<script>
// TODO check if non-first step/entity may be selected initially

import { cloneDeep } from "lodash";
import axios from "@/axios";

import ModalConfirm from "@shared/modal-confirm/index.vue";
import Loader from "@shared/loader/index.vue";
import FormBuilder from "@shared/components/form-builder";

import NavBar from "./components/NavBar";
import EntityBar from "./components/EntityBar";
import PackageList from "./components/PackageList";
import CheckList from "./components/ChecksList";
import EntityChangeNav from "./components/EntityChangeNav.vue";
import CollectInfo from "./components/CollectInfo.vue";

import { EntityForm } from "./utils";
import { CASE_CREATION_STEPS, CHANGE_STATUSES } from "./utils/constants.js";

const caseCreationStepsList = [
  {
    id: CASE_CREATION_STEPS.ENTITY_DETAILS,
    label: "Entity Details",
  },
  {
    id: CASE_CREATION_STEPS.COLLECT_INFO,
    label: "Collect Info",
  },
  {
    id: CASE_CREATION_STEPS.CHECKS,
    label: "Checks",
  },
  {
    id: CASE_CREATION_STEPS.SUBMIT,
    label: "Submit",
  },
];

export default {
  name: "create-case",
  components: {
    NavBar,
    EntityBar,
    PackageList,
    Loader,
    FormBuilder,
    EntityChangeNav,
    ModalConfirm,
    CollectInfo,
    CheckList,
  },
  props: {
    tenantId: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    // is used when packages are loading:
    loading: {
      type: Boolean,
      default: false,
    },
    preselectedPackageId: {
      type: String,
    },
    packageList: {
      type: Array,
      default: () => [],
    },
    portal: {
      type: String,
      default: "tenant",
    },
  },
  data() {
    return {
      isLoading: false, // global loader for this component
      CASE_CREATION_STEPS,
      selectedPackageId: null,
      tempCaseId: null,
      caseTypeEntityId: null,
      caseEntities: null,
      caseEntitiesInfo: null,
      caseEntityTypesInfo: null,
      caseEntityForms: {},
      selectedEntityId: null,
      loaders: {
        case: false,
        form: false, // use to set loading status for the current form
        entity: false,
        step: false,
      },
      addEntityLoaders: {},
      formStates: {
        loading: false,
        submitted: false,
      },
      packageLoaders: {},
      showEntityChangeNav: false,
      collectInfoData: null, // data used for the Collect Info form
      entitiesCollectInfo: null, // collect info saved to the server
      entitiesCollectInfoUpdated: null, // updated & not saved collect info
      // use to determin what to do on "save & next"
      collectDataStatuses: {
        existOnInit: null,
        changeStatus: null,
      },
      currentCaseCreationStep: null,
      caseCheckList: null,
      formBuilderActionsNames: {
        submit:  'Save',
        preview: 'Preview'
      }
    };
  },

  computed: {
    showCaseCreation() {
      return (
        this.selectedPackage &&
        !this.packageLoaders[this.selectedPackageId] &&
        this.computedCaseData
      );
    },
    selectedPackage() {
      return this.selectedPackageId
        ? this.packageList.find((el) => el.id === this.selectedPackageId)
        : null;
    },
    currentStepIndex() {
      // use positive value
      if (!this.currentCaseCreationStep) return 1;
      return (
        this.caseCreationSteps.findIndex(
          (el) => el.id === this.currentCaseCreationStep.id
        ) + 1
      );
    },
    timelineData() {
      return {
        list: this.caseCreationSteps,
        current: this.currentCaseCreationStep,
        currentStepIndex: this.currentStepIndex,
      };
    },
    caseEntity() {
      // find an entity with the case temp data:
      return (
        this.caseEntities?.find((el) => el.id === this.caseTypeEntityId) || null
      );
    },
    computedCaseData() {
      if (!this.caseEntity) return null;
      return {
        caseEntity: this.caseEntity,
        name: this.caseEntity.entity_name,
      };
    },
    caseCreationSteps() {
      const caseEntityTypesInfo = this.caseEntityTypesInfo
        ? Object.values(this.caseEntityTypesInfo)
        : [];
      const isCollectionDataNeeded = caseEntityTypesInfo.some(
        (x) => x.collect_data
      );
      return isCollectionDataNeeded
        ? caseCreationStepsList
        : caseCreationStepsList.filter(
            (x) => x.id != CASE_CREATION_STEPS.COLLECT_INFO
          );
    },
    // editable entities
    computedEntitiesList() {
      if (!this.caseEntity || !this.caseEntities) return null;

      return this.caseEntities.map((el) => {
        return {
          data: el,
          id: el.id,
          collect_data: el.collect_data,
          type: this.caseEntityTypesInfo[el.entity_type_id]?.name,
          name: el.entity_name || "",
          readonly: el.id === this.caseTypeEntityId,
          disabled:
            this.currentCaseCreationStep.id ==
              CASE_CREATION_STEPS.COLLECT_INFO && !el.collect_data,
          selected: el.id === this.selectedEntityId,
          form: this.caseEntityForms[el.id] || null,
          checked: this.collectDataStatuses[el.id]?.existOnInit,
          warning:
            this.checkCurrentStep(CASE_CREATION_STEPS.COLLECT_INFO) &&
            el.id !== this.selectedEntityId &&
            !!this.collectDataStatuses[el.id]?.changeStatus, // highlight if isn't saved & not selected
        };
      });
    },
    selectedEntity() {
      if (!this.selectedEntityId || !this.computedEntitiesList) return null;
      return (
        this.computedEntitiesList.find(
          (el) => el.id === this.selectedEntityId
        ) || null
      );
    },
    entitiesCounter() {
      // calculate dynamic counts considering newly created entities
      if (!this.caseEntityTypesInfo || !this.caseEntities) return null;
      // find entity with 'Case' type:
      const caseEntity = this.caseEntities.find(
        (el) => el.id === this.caseTypeEntityId
      );

      const resultCounter = {};
      // consider minimum counts of other entities
      const totalMinCount = Object.values(this.caseEntityTypesInfo).reduce(
        (result, current) => {
          return result + current.min;
        },
        0
      );
      const maxEntitiesCount = this.caseEntitiesInfo.max_case_entity_count;
      // consider "Case" entity
      const totalCount = this.caseEntities.length;
      const totalRemaining = maxEntitiesCount - totalCount;

      resultCounter.entities = Object.entries(this.caseEntityTypesInfo)
        .filter(([key]) => key !== caseEntity.entity_type_id)
        .reduce((result, [key, entityInfo]) => {
          const otherTotalMinCount = totalMinCount - entityInfo.min;
          result.push({
            ...entityInfo,
            id: key,
            max: entityInfo.max,
            calculatedMax: maxEntitiesCount - otherTotalMinCount,
            min: entityInfo.min,
            count: this.caseEntities.filter((el) => el.entity_type_id === key)
              .length, // consider other entities' min count that must be added to the case
            remaining: Math.min(
              entityInfo.max -
                this.caseEntities.filter((el) => el.entity_type_id === key)
                  .length,
              totalRemaining
            ),
          });
          return result;
        }, []);
      resultCounter.total = {
        max: maxEntitiesCount,
        count: totalCount,
        remaining: totalRemaining,
      };
      return resultCounter;
    },
    isAllFormsSubmitted() {
      return !Object.values(this.caseEntityForms).some((el) => !el.isSubmitted);
    },
    isNextEntity() {
      const index = this.getNextEntityIndex();
      return !!index;
    },
    isNextStep() {
      // steps numbering starts from 1 so it already contains +1 when we change the selected step:
      return !!this.caseCreationSteps[this.currentStepIndex];
    },
    allowNextForm() {
      if (!this.entitiesCounter?.entities) return false;
      return (
        this.isNextEntity &&
        this.caseEntityForms[this.selectedEntityId].isSubmitted
      );
    },
    allowSubmitForm() {
      if (!this.entitiesCounter?.entities || !!this.allowNextForm) return false;
      return (
        this.isAllFormsSubmitted &&
        !Object.values(this.entitiesCounter.entities).some(
          (el) => el.count < el.min
        )
      );
    },
    entitiesToAdd() {
      if (
        !this.entitiesCounter?.entities ||
        this.entitiesCounter?.total.count >= this.entitiesCounter?.total.max
      )
        return [];
      return (
        this.entitiesCounter.entities
          // .filter((el) => el.count < el.max)
          .map((el) => ({
            id: el.id,
            name: el.name,
            entity: el,
            disabled: el.count >= el.calculatedMax, // max hasn't been reached but creation of new entity will lead to invalid entities structure
            visible: el.count < el.max,
            loading: this.addEntityLoaders[el.id] || false,
          }))
      );
    },
    currentEntityCollectInfo() {
      if (
        !this.collectInfoData ||
        !this.selectedEntity ||
        !this.entitiesCollectInfoUpdated
      )
        return null;
      return (
        this.entitiesCollectInfoUpdated.find(
          (el) => el.case_entity_id === this.selectedEntityId
        ) || null
      );
    },
    entityDataCollectionModel: {
      get() {
        if (!this.currentEntityCollectInfo) {
          return {
            active: false,
            details: null,
          };
        } else {
          return {
            active: true,
            details: {
              form: this.currentEntityCollectInfo.forms[0]?.form_id,
              user: this.currentEntityCollectInfo.email,
              sequence: this.currentEntityCollectInfo.email_sequence_id,
              role: this.currentEntityCollectInfo.forms?.[0]?.role,
            },
          };
        }
      },
      set(val) {
        if (!val?.active && !!this.currentEntityCollectInfo) {
          // REMOVE
          const currentIndex = this.entitiesCollectInfoUpdated.findIndex(
            (el) => el.case_entity_id === this.selectedEntityId
          );
          this.entitiesCollectInfoUpdated.splice(currentIndex, 1);

          // save change status for this entity form:
          this.$set(
            this.collectDataStatuses[this.selectedEntityId],
            "changeStatus",
            CHANGE_STATUSES.DELETED
          );
        } else if (val?.active && !!this.currentEntityCollectInfo) {
          // CHANGE
          Object.assign(this.currentEntityCollectInfo, {
            // we always send the same form but with changeable role selected is User field
            forms: [
              {
                form_id: this.selectedEntity.data.form_id,
                role: val.details?.role,
                form_type: "CUSTOMIZED_CASE_ENTITY_FORM",
              },
            ],
            email: val.details?.user,
            email_sequence_id: val.details?.sequence,
          });

          // save change status for this entity form:
          this.$set(
            this.collectDataStatuses[this.selectedEntityId],
            "changeStatus",
            CHANGE_STATUSES.UPDATED
          );
        } else if (val?.active && !this.currentEntityCollectInfo) {
          // ADD
          this.entitiesCollectInfoUpdated.push({
            case_id: this.tempCaseId,
            // external_id: this.tenantId,
            // external_id_type: '',
            active: true,
            case_entity_id: this.selectedEntityId,
            // we always send the same form but with changeable role selected is User field
            forms: [
              {
                form_id: this.selectedEntity.data.form_id,
                role: val.details?.role,
                form_type: "CUSTOMIZED_CASE_ENTITY_FORM",
              },
            ],
            email: val.details?.user,
            email_sequence_id: val.details?.sequence,
          });

          // save change status for this entity form:
          this.$set(
            this.collectDataStatuses[this.selectedEntityId],
            "changeStatus",
            CHANGE_STATUSES.ADDED
          );
        }
      },
    },
    allDataCollectionsSaved() {
      return !this.computedEntitiesList
        .filter((x) => x.disabled)
        .every((x) => !!this.collectDataStatuses?.[x.id]?.changeStatus);
    },
    currentEntityCheckList() {
      return this.caseCheckList || [];
    },
  },
  async mounted() {
    const packageid = this.preselectedPackageId;
    this.resetPackage(packageid || null);
    if (this.preselectedPackageId) {
      // show a loader while fetching temp data for initially selected package
      // because loader of a particular package card isn't shown
      this.isLoading = true;
      await this.selectPackage(this.preselectedPackageId);
      this.isLoading = false;
    }
  },
  created() {
    this.resetCreationStep();
  },
  methods: {
    checkCurrentStep(stepId) {
      return this.currentCaseCreationStep.id === stepId;
    },
    async onBack() {
      // currentStepIndex starts from 1 so we need to go to the -2 index in array
      if (this.currentStepIndex > 1) {
        await this.selectStep(
          this.caseCreationSteps[this.currentStepIndex - 2]
        );
      } else {
        this.exitCaseCreation();
      }
    },
    async onCancel() {
      try {
        const cancelConfirmed = await this.$refs["confirm-popup"].show({
          title: "Are you sure?",
          message: "The case data will be lost.",
        });
        if (!cancelConfirmed) return;
        await this.deleteTempCaseData();
        this.exitCaseCreation();
      } catch (error) {
        this.handleError(error);
      }
    },
    async deleteTempCaseData() {
      await axios.delete(`/case/${this.tempCaseId}`);
    },
    setCollectionDataStatuses() {
      if (!this.caseEntities?.length || !this.entitiesCollectInfoUpdated)
        return;
      this.caseEntities.forEach((entity) => {
        this.resetCollectDataStatuses(entity.id);
        this.$set(this.collectDataStatuses[entity.id], "changeStatus", null);
        this.$set(
          this.collectDataStatuses[entity.id],
          "existOnInit",
          this.entitiesCollectInfoUpdated.some(
            (el) => el.case_entity_id === entity.id
          )
        );
      });
    },
    async selectStep(step) {
      const initStepMethods = {
        [CASE_CREATION_STEPS.ENTITY_DETAILS]: async () => {
          const entityToSelectId =
            (this.caseEntities.find((el) => el.active) || this.caseEntities[0])
              ?.id || null;
          if (entityToSelectId) await this.selectEntity(entityToSelectId);
        },
        [CASE_CREATION_STEPS.COLLECT_INFO]: async () => {
          this.loaders.step = true;
          try {
            this.collectInfoData = {};
            const index = this.getNextEntityIndex("stepInited");
            const entity = this.computedEntitiesList[index];
            await this.selectEntity(entity?.id);
            const initPromises = [
              axios(`email-sequence/${this.tempCaseId}/data-collection`),
              axios("email-sequence/template-sequence-linearlist", {
                params: { external_id: this.tenantId },
              }),
            ];
            const [entitiesCollectInfo, sequenceList] = await Promise.all(
              initPromises
            );
            this.entitiesCollectInfo = entitiesCollectInfo?.data?.data || null;
            this.entitiesCollectInfoUpdated = cloneDeep(
              this.entitiesCollectInfo
            );
            this.collectInfoData.sequenceList = sequenceList?.data || [];
            this.setCollectionDataStatuses();
          } catch (error) {
            this.handleError(error);
          }
          this.loaders.step = false;
        },
        [CASE_CREATION_STEPS.CHECKS]: async () => {
          const index = this.getNextEntityIndex("stepInited");
          const entity = this.computedEntitiesList[index];
          await this.selectEntity(entity?.id);
        },
        [CASE_CREATION_STEPS.SUBMIT]: () => {
          // on the last step, we keep the last entity selected
        },
      };
      this.currentCaseCreationStep = step;
      const { id } = step;
      try {
        await initStepMethods[id]?.();
      } catch (error) {
        this.handleError(error);
      }
    },
    async nextStep() {
      // steps numbering starts from 1 so it already contains +1 when we change the selected step:
      await this.selectStep(this.caseCreationSteps[this.currentStepIndex]);
    },
    resetPackage(payload = null) {
      this.selectedPackageId = payload;
      this.currentCaseCreationStep = this.caseCreationSteps[0];
      this.caseEntitiesOrder = null;
      this.resetCaseCreation();
      if (!payload) {
        this.$emit("cancel");
      }
    },
    resetCaseCreation() {
      this.tempCaseId = null;
      this.caseEntitiesInfo = null;
      this.caseEntityTypesInfo = null;
      this.caseEntities = null;
      this.caseEntityForms = {};
      this.selectedEntityId = null;
      this.showEntityChangeNav = false;
      this.caseCheckList = null;
      this.collectInfoData = null;
      this.resetCollectDataStatuses();
    },
    resetCreationStep() {
      this.currentCaseCreationStep = this.caseCreationSteps[0];
    },
    exitCaseCreation() {
      this.resetPackage();
      this.$emit("cancel");
    },
    handleError(error) {
      console.log("error :>> ", error);
      this.$toast.error(
        error.response?.data?.detail[0]?.msg ||
          error.response?.data?.detail ||
          `Something went wrong!`
      );
    },
    async initCaseCreationProcess() {
      await this.selectStep(this.caseCreationSteps[0]);
    },
    async selectPackage(packageId) {
      this.selectedPackageId = packageId;
      this.$set(this.packageLoaders, packageId, true);
      try {
        this.resetCreationStep();
        this.$router.push({
          query: { ...this.$route.query, packageid: packageId },
        });
        await this.createTempCase();
        this.$set(this.packageLoaders, packageId, false);
        this.$emit("select:package", packageId);
        await this.initCaseCreationProcess();
      } catch (error) {
        this.handleError(error);
        this.resetPackage();
        this.$set(this.packageLoaders, packageId, false);
      }
    },
    beforeSelectEntity() {
      const formBuilder = this.$refs?.["case-creation-form-builder"];
      if (!formBuilder) return true;
      return !formBuilder.isDirty;
    },
    resetCollectDataStatuses(key = null) {
      if (key) {
        this.$set(this.collectDataStatuses, key, {
          existOnInit: null,
          changeStatus: null,
        });
      } else {
        this.collectDataStatuses = {};
      }
    },
    async onSelectEntity(id) {
      const canChangeTab = this.beforeSelectEntity(this.selectedEntity);
      if (!canChangeTab) {
        this.$toast.error("Please save the section data");
        return;
      }
      await this.selectEntity(id);
    },
    async selectEntity(id, hideEntityChangeNav = true, submit = false) {
      const initEntityMethods = {
        [CASE_CREATION_STEPS.ENTITY_DETAILS]: async () => {
          await this.fetchEntityForm(id, submit);
        },
        [CASE_CREATION_STEPS.COLLECT_INFO]: async () => {
          const userList = await axios(`case/${id}/email`);
          this.collectInfoData.userList = userList?.data || [];
          this.collectInfoData.formList = [
            {
              label: "Customized Form",
              value: this.selectedEntity.data.form_id,
              id: this.selectedEntity.data.form_id,
            },
          ];
        },
        [CASE_CREATION_STEPS.CHECKS]: async () => {
          this.loaders.step = true;
          try {
            let url = `case/${this.tempCaseId}/case_entity/${id}/check`;
            if (this.portal === "client") {
              url = `case/${this.tempCaseId}/case_entity/${id}/check/client`;
            }
            const { data } = await axios(url);
            this.caseCheckList = data?.data || [];
          } catch (error) {
            this.handleError(error);
          }
          this.loaders.step = false;
        },
        // [CASE_CREATION_STEPS.SUBMIT]: () => {},
      };

      if (!id) this.selectedEntityId = null;
      else {
        this.selectedEntityId = id;

        this.loaders.entity = true;
        try {
          await initEntityMethods[this.currentCaseCreationStep.id]?.();
        } catch (error) {
          this.handleError(error);
        }
        this.loaders.entity = false;
      }
      if (hideEntityChangeNav) this.showEntityChangeNav = false;
    },
    setCaseEntitiesOrder(case_entities) {
      if (!this.caseEntitiesOrder) {
        const caseEntityIndex = case_entities.findIndex(
          (el) => el.id === this.caseTypeEntityId
        );
        const resultList = [...case_entities];
        const caseEntity = resultList.splice(caseEntityIndex, 1);
        resultList.unshift(...caseEntity);
        this.caseEntitiesOrder = resultList.map((i) => i.id);
      } else {
        case_entities.forEach((i) => {
          if (!this.caseEntitiesOrder.includes(i.id)) {
            this.caseEntitiesOrder.push(i.id);
          }
        });
      }
      return case_entities.reduce((acc, v) => {
        const index = this.caseEntitiesOrder.findIndex((x) => x == v.id);
        acc[index] = v;
        return acc;
      }, []);
    },
    storeEntityData(payload) {
      const {
        case_entities,
        case_entity_info,
        case_entity_type_info,
      } = payload;
      this.caseEntities = this.setCaseEntitiesOrder(case_entities);
      this.caseEntitiesInfo = case_entity_info;
      this.caseEntityTypesInfo = case_entity_type_info;
    },
    async getCaseEntityData(case_id) {
      const { data } = await axios(`case/${case_id}/entity`);
      this.storeEntityData(data);
    },

    // create temp case
    async createTempCase() {
      const payload = {
        package_id: this.selectedPackageId,
        client_id: this.clientId,
      };
      const { data } = await axios.post(
        `case/create-temp-multientity-case`,
        payload
      );
      this.tempCaseId = data.case_id;
      this.caseTypeEntityId = data.case_type_entity_id;

      this.storeEntityData(data.entity_info);
    },
    async fetchEntityForm(case_entity_id, submit) {
      const { data } = await axios(
        `case/${this.tempCaseId}/entity/${case_entity_id}/case-entity-creation-form-v2`
      );

      const entity = new EntityForm(data);
      // need to submit fetched entity when after entity was removed
      if (submit) entity.submit()
      this.$set(this.caseEntityForms, case_entity_id, entity);
    },
    formatSections(data) {
      return { sections: [data.data] };
    },
    async saveEntitySection(payload) {
      this.formStates.loading = true;
      let sectionSaved = false;
      try {
        await axios.post(
          `case/${this.tempCaseId}/entity/${this.selectedEntity.id}/case-entity-creation-form`,
          this.formatSections(payload)
        );
        sectionSaved = true;
      } catch (error) {
        this.handleError(error);
        sectionSaved = false;
      }
      this.formStates.loading = false;
      return sectionSaved;
    },
    async onEntityFormSubmitted() {
      this.selectedEntity.form.submit();
      this.showEntityChangeNav = true;
      const { data } = await axios.get(`case/${this.tempCaseId}/entity`);
      this.storeEntityData(data);
      // In case we need updated entity form data we should store updated data from the FormBuilder. Currently, all updated data is available until FormBuilder is rerendered
    },
    async submitEntityForm() {
      this.formStates.loading = true;
      try {
        await axios.post(
          `case/${this.tempCaseId}/entity/${this.selectedEntity.id}/case-entity-creation-form/submit`
        );
        this.onEntityFormSubmitted();
      } catch (error) {
        this.handleError(error);
      }
      this.formStates.loading = false;
    },
    async addEntity(payload) {
      const { id } = payload; // entity_type_id
      try {
        this.$set(this.addEntityLoaders, id, true);
        const { data } = await axios.post(`case/entity`, {
          case_id: this.tempCaseId,
          entity_type_id: id,
        });
        const newEntity = {
          ...this.selectedEntity.data,
          id: data.case_entity_id,
          form_id: data.form_id,
          active: false,
          created_at: null,
          entity_name: null,
          entity_type_id: id,
          form_status_id: null,
          form_status_updated_at: null,
        };
        const currentEntityIndex = this.caseEntities.findIndex(
          (el) => el.id === this.selectedEntityId
        );
        this.caseEntities.splice(currentEntityIndex + 1, 0, newEntity);
        await this.selectEntity(newEntity.id);
      } catch (error) {
        this.handleError(error);
      }
      this.$set(this.addEntityLoaders, id, false);
    },
    async removeEntity(payload) {
      const { id } = payload; // entity_type_id
      const isCurrent = this.selectedEntityId === id;
      const currentEntityIndex = this.caseEntities.findIndex(
        (el) => el.id === id
      );
      const currentComputedEntityIndex = this.computedEntitiesList.findIndex(
        (el) => el.id === id
      );
      try {
        const removeConfirmed = await this.$refs["confirm-popup"].show({
          title: "Are you sure?",
          message:
            "This entity will be deleted permanently. Please confirm to continue deleting this entity.",
        });
        if (removeConfirmed) {
          await axios.delete(`case/${this.tempCaseId}/entity/${id}`);
          // select previous entity if removed was selected
          if (isCurrent)
            await this.selectEntity(this.caseEntities[currentComputedEntityIndex - 1].id, false, true);
          // remove entity from list:
          this.caseEntities.splice(currentEntityIndex, 1);
          this.caseEntitiesOrder = this.caseEntitiesOrder.filter(x => x!=id);
          // remove related form:
          delete this.caseEntityForms[id];
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    getNextEntityIndex(action) {
      let nextIndex = 0;
      if (action != "stepInited") {
        const currentEntityIndex = this.computedEntitiesList.findIndex(
          (el) => el.id === this.selectedEntityId
        );
        nextIndex = currentEntityIndex + 1;
      }

      let enabledIndex;
      for (let i = nextIndex; i < this.computedEntitiesList.length; i++) {
        if (!this.computedEntitiesList[i].disabled) {
          enabledIndex = i;
          break;
        }
      }
      return enabledIndex;
    },

    async selectNextEntity() {
      const entityIndex = this.getNextEntityIndex();
      if (entityIndex) {
        await this.selectEntity(this.computedEntitiesList[entityIndex].id);
        return;
      }

      const nextStep =
        this.caseCreationSteps.findIndex(
          (x) => x.id == this.currentCaseCreationStep.id
        ) + 1;
      if (nextStep) {
        this.selectStep(this.caseCreationSteps[nextStep], true);
      }
    },
    async saveCollectInfo() {
      this.loaders.form = true;
      try {
        const { existOnInit, changeStatus } = this.collectDataStatuses[
          this.selectedEntityId
        ];
        const checkStatus = (status) => status === changeStatus;

        if (
          !existOnInit &&
          (checkStatus(CHANGE_STATUSES.ADDED) ||
            checkStatus(CHANGE_STATUSES.UPDATED))
        ) {
          // post data collection:
          const { data } = await axios.post(`email-sequence/data-collection`, [
            this.currentEntityCollectInfo,
          ]);
          // update status saved on server:
          this.$set(
            this.collectDataStatuses[this.selectedEntityId],
            "existOnInit",
            true
          );
          // add new entity to the original list:
          const newCollection = cloneDeep(this.currentEntityCollectInfo);
          this.entitiesCollectInfo.push(newCollection);
          // save the ID received from the response to the newCollection:
          const savedCollection = data.data.find(
            (el) => el.case_entity_id === this.selectedEntityId
          );
          newCollection.id = savedCollection.id;
          this.currentEntityCollectInfo.id = savedCollection.id;
        } else if (
          existOnInit &&
          (checkStatus(CHANGE_STATUSES.ADDED) ||
            checkStatus(CHANGE_STATUSES.UPDATED))
        ) {
          // update data collection:
          await axios.put(
            `email-sequence/data-collection`,
            this.currentEntityCollectInfo
          );

          // update status saved on server:
          this.$set(
            this.collectDataStatuses[this.selectedEntityId],
            "existOnInit",
            true
          );
          // update current entity in original list:
          const entityIndex = this.entitiesCollectInfo.findIndex(
            (el) => el.case_entity_id === this.selectedEntityId
          );
          this.entitiesCollectInfo.splice(
            entityIndex,
            1,
            cloneDeep(this.currentEntityCollectInfo)
          );
        } else if (existOnInit && checkStatus(CHANGE_STATUSES.DELETED)) {
          // delete data collection:
          const removedDataCollection = this.entitiesCollectInfo.find(
            (el) => el.case_entity_id === this.selectedEntityId
          );
          await axios.delete(
            `email-sequence/data-collection/${removedDataCollection.id}`
          );
          // remove current entity from original list:
          this.$set(
            this.collectDataStatuses[this.selectedEntityId],
            "existOnInit",
            false
          );
          const entityIndex = this.entitiesCollectInfo.findIndex(
            (el) => el.case_entity_id === this.selectedEntityId
          );
          this.entitiesCollectInfo.splice(entityIndex, 1);
        }
        // else skip action and go to the next entity

        // reset change status:
        this.$set(
          this.collectDataStatuses[this.selectedEntityId],
          "changeStatus",
          null
        );

        await this.selectNextEntity();
      } catch (error) {
        this.handleError(error);
      }
      this.loaders.form = false;
    },
    async submitCase() {
      try {
        const submissionConfirmed = await this.$refs["finish-popup"].show({
          title: "Are you sure?",
        });
        if (submissionConfirmed) {
          const response = await axios.post(
            `case/multi-entity/${this.tempCaseId}/submit`
          );
          this.$toast.success(
            response?.data?.message || "Case Created Successfully!"
          );
          this.exitCaseCreation();
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    startCaseCreation() {
      this.currentCaseCreationStep = this.caseCreationSteps[0];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.submit-confirmation-text {
  list-style: disc;
  padding-left: toRem(20px);
}
</style>
