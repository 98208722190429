import { render, staticRenderFns } from "./ChecksList.vue?vue&type=template&id=4b92aecc&scoped=true"
import script from "./ChecksList.vue?vue&type=script&lang=js"
export * from "./ChecksList.vue?vue&type=script&lang=js"
import style0 from "./ChecksList.vue?vue&type=style&index=0&id=4b92aecc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b92aecc",
  null
  
)

export default component.exports