<template>
        <div class="collect-info flex flex-col w-full gap-5">
            <UIContentBox class="collect-info__form flex flex-col w-full gap-5">
                <label class="flex gap-3 items-center text-lg cursor-pointer font-bold">
                    <input
                        v-model="activeModel"
                        type="checkbox"
                        class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-md pointer-events-none w-6 h-6"
                    />
                    Collect further information
                </label>
                <CollectInfoForm
                    v-if="activeModel"
                    v-model="detailsModel"
                    :config="{
                        options: detailsOptions,
                        props: detailsProps,
                    }"
                />
            </UIContentBox>
            <UIContentBox class="flex items-center justify-center">
                <template v-if="!(isLast && allSaved)">
                    <Button
                        v-if="!this.value?.active"
                        :loader="loading"
                        type="primary"
                        @click="$emit('save')"
                        :text="handleButtonText('Skip')"
                    />
                    <Button
                        v-else
                        :loader="loading"
                        type="primary"
                        @click="$emit('save')"
                        :text="handleButtonText('Save')"
                        :disabled="!canSubmit"
                    />
                </template>
                <Button
                    v-else
                    :loader="loading"
                    class="bg-success text-white"
                    @click="$emit('submit')"
                    :disabled="!canSubmit"
                    text="Submit"
                />
            </UIContentBox>
        </div>
</template>

<script>
import CollectInfoForm from "@shared/components/CollectInfoForm/index.vue";
import Button from "@shared/components/button";

export default {
    components: {
        Button,
        CollectInfoForm,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            required: true,
        },
        formOptions: {
            type: Array,
            default: () => [],
        },
        userOptions: {
            type: Array,
            default: () => [],
        },
        sequenceOptions: {
            type: Array,
            default: () => [],
        },
        isLast: {
            type: Boolean,
            default: false,
        },
        allSaved: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        canSubmit() {
            return !this.activeModel || (
                this.detailsModel?.form 
                && this.detailsModel?.user 
                && this.detailsModel?.sequence
            )
        },
        activeModel: {
            get() {
                return this.value?.active || false;
            },
            set(val) {
                this.$emit('input', {
                    active: val,
                    details: this.detailsModel,
                });
            },
        },
        detailsOptions() {
            return {
                form: this.formOptions,
                user: this.userOptions,
                sequence: this.sequenceOptions,
            }
        },
        detailsProps() {
            const defaultProps = {
                validation: 'required',
            }
            
            return {
                form: defaultProps,
                user: defaultProps,
                sequence: defaultProps,
            }
        },
        detailsModel: {
            get() {
                return this.value?.details || null;
            },
            set(val) {
                this.$emit('input', {
                    active: this.activeModel,
                    details: val,
                })
            },
        },
    },
    methods: {
        handleButtonText(text) {
            return this.isLast ? `${text} and Next` : text;
        },
    },
}
</script>

<style lang="scss" scoped>
</style>